import React from "react"
import { graphql } from "gatsby"

// Components
import WhitepaperPage from "../pageViews/whitepaperPageView"

const WhitepaperPageContainer = ({ data }: any) => {
  if (!data.alWhitepapers) {
    return null
  }

  // const rawData = 'data.alWhitepapers.nodes'

  const formattedData: any = {
    metaTitle: 'Whitepapers',
    metaDescription: 'All whitepapers',
    pageTitle: 'Whitepapers',
    introTitle: 'All whitepapers',
    introCopy: 'All whitepapers',
  }

  // Format posts by year
  const formattedPosts = data.alWhitepapers.nodes.reduce(
    (acc: any, blogPost: any) => {
      // Find the post's publication year
      const pubDateParts = blogPost.first_publication_date.split("-")
      const pubYear = pubDateParts[0]

      // Inherit or create a new array to hold all posts from the pub year
      acc[pubYear] = acc[pubYear] || []

      // Format the post
      const formattedPostData = {
        title: blogPost.data.document_title,
        slug: blogPost.uid,
        type: 'White paper',
        date: blogPost.first_publication_date,
        image: '',
      }

      // Push any new posts
      acc[pubYear].push(formattedPostData)

      return acc
    },
    {}
  )

  return <WhitepaperPage data={formattedData} posts={formattedPosts} />
}

export const query = graphql`
  query GetAllWhitepapers {
    alWhitepapers: allPrismicWhitepaperPage {
      nodes {
        data {
          document_title
        }
        first_publication_date
        uid
      }
    }
  }
`

export default WhitepaperPageContainer
